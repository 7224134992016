import React from "react";
import dv from "../../images/dv.jpeg";

import CountUp from "react-countup";

const Hero = () => {
  const [title, setTitle] = React.useState("Dishit Vasani");
  React.useEffect(() => {
    setTitle((document.title = title));
  }, [window.onload]);

  return (
    <section className="pt-0" id="about">
      <div className="container pt-14">
        <div className="md:flex items-center justify-between sm:flex-col md:flex-row">
          {/* her left start */}
          <div className="w-full md:basis-1/2">
            <h5
              data-aos="fade-right"
              data-aos-duration="1500"
              className="text-headingColor font-[600]
                        text-[25px]"
            >
              Hi,
            </h5>

            <h1
              data-aos="fade-right"
              data-aos-duration="1500"
              className="text-headingColor font-[800] text-[1.8rem] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5"
            >
              I'm Dishit Vasani <br />
            </h1>
            <h3 data-aos="fade-right" data-aos-duration="1500">
              Information & Technology Enthusiast | React/Node/Angular/PHP |
              Python | SQL | Ex-Crrank | Ex-Numerator
            </h3>
            <div
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="150"
              className="flex items-center gap-6 mt-7"
            >
              <a href="#contact">
                <button className="text-smallTextColor font-[600] text-[16px] border-b border-solid border-smallTextColor">
                  Contact{" "}
                </button>
              </a>

              <a
                href="#portfolio"
                className="text-smallTextColor font-[600] text-[16px] border-b border-solid border-smallTextColor"
              >
                See Portfolio
              </a>
            </div>

            <p
              data-aos="fade-right"
              data-aos-duration="1500"
              className="text-headingColor mt-12 font-[500] text-[16px] leading-7"
            >
              <b>
                Greetings! I'm a dynamic software engineer from Toronto, Canada,
                with a robust background in web development, a diverse range of
                programming languages, and a passion for data analysis. I'm
                dedicated to creating innovative solutions that exceed
                expectations. My expertise spans frontend and backend
                technologies, data visualization, and database management. Let's
                collaborate to bring your projects to life and shape the future
                of technology. Get in touch, and let's innovate together!
              </b>
            </p>

            <div className="flex items-center gap-9 mt-14">
              <span className="text-smallTextColor text-[20px] font-[600]">
                Connect
              </span>
              <span>
                <a
                  title="LinkedIn"
                  target="_blank"
                  href="https://www.linkedin.com/in/dishit-vasani/"
                  className="text-smallTextColor text-[25px] font-[600]"
                >
                  <i class="ri-linkedin-box-fill"></i>
                </a>
              </span>
              <span>
                <a
                  title="github"
                  target="_blank"
                  href="https://github.com/dvasani15"
                  className="text-smallTextColor text-[25px] font-[600]"
                >
                  <i class="ri-github-line"></i>
                </a>
              </span>
              <span>
                <a
                  title="github"
                  target="_blank"
                  href="mailto:dvasani15@gmail.com"
                  className="text-smallTextColor text-[25px] font-[600]"
                >
                  <i class="ri-mail-line"></i>
                </a>
              </span>
              <span>
                <a
                  title="instagram"
                  target="_blank"
                  href="https://www.instagram.com/dishit_v/"
                  className="text-smallTextColor text-[25px] font-[600]"
                >
                  <i class="ri-instagram-fill"></i>
                </a>
              </span>
            </div>
          </div>
          {/* hero left end */}

          {/* hero img */}
          <div
            data-aos="fade-right"
            data-aos-duration="1500"
            className="text-headingColor font-[800] text-[1.8rem] sm:text-[40px] leading-[35px] sm:leading-[46px] mt-5 basis-1/3 mt-10 sm:mt-0"
            style={{ marginLeft: "20px" }}
          >
            <figure className="flex items-center justify-center">
              <img src={dv} alt="" />
            </figure>
          </div>

          {/* hero img end */}

          {/* hero image content right */}
          <div
            className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col
                        md:justify-end md:text-end"
          >
            <div className="mb-10">
              <span>
                <a href="#" className="text-smallTextColor text-[80px] ">
                  <i class="ri-reactjs-line"></i>
                </a>
              </span>
              <CountUp
                className="text-headingColor font-[600] bold text-[20px]"
                start={0}
                end={85}
                duration={3}
                suffix="%"
              />
            </div>
            <div className="mb-10">
              <span>
                <a href="#" className="text-smallTextColor text-[80px] ">
                  <i class="ri-database-2-fill"></i>
                </a>
              </span>

              <CountUp
                className="text-headingColor font-[600] bold text-[20px]"
                start={0}
                end={85}
                duration={3}
                suffix="%"
              />
            </div>
            <div className="mb-10">
              <span>
                <a href="#" className="text-smallTextColor text-[80px]">
                  <i class="ri-angularjs-fill"></i>
                </a>
              </span>
              <CountUp
                className="text-headingColor font-[600] bold text-[20px]"
                start={0}
                end={75}
                duration={3}
                suffix="%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
