import p1 from "../images/p1.png";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
import p4 from "../images/p4.png";
import p5 from "../images/p5.png";
const portfolios = [
  {
    id: "01",
    imgUrl: p1,
    category: "python",
    title: "Deep Learning",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
    technologies: ["Python", "CNN", "Deep Learning"],
    siteUrl: "https://github.com/dvasani15/Image-Classification-CNN",
  },
  {
    id: "02",
    imgUrl: p2,
    category: "dv",
    title: "Tableau",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
    technologies: ["Tableau"],
    siteUrl: "https://public.tableau.com/app/profile/dishit.vasani",
  },
  {
    id: "03",
    imgUrl: p3,
    category: "python",
    title: "Machine Learning",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
    technologies: ["Python"],
    siteUrl: "https://github.com/dvasani15/Machine-Learning",
  },
  {
    id: "04",
    imgUrl: p4,
    category: "python",
    title: "Web Scrapping",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
    technologies: ["Python"],
    siteUrl: "https://github.com/dvasani15/Web-Scrapping",
  },
  {
    id: "05",
    imgUrl: p5,
    category: "python",
    title: "Credit-Card Fraud Detection",
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium vitae sunt magnam numquam esse ipsam? Quis qui enim, temporibus dignissimos atque repellendus amet a velit. Dolorem quidem animi doloribus saepe!",
    technologies: ["Python"],
    siteUrl: "https://github.com/dvasani15/CreditCard-Fraud",
  },
];


export default portfolios;
