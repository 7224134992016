import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-[#00070D] pt-12">
      <div className="container">
        <div className="sm:flex items-center justify-between md:gap-8">
          <div className="w-full sm:w-1/2">
            <a href="#contact">
              <button
                className="flex items-center gap-2 text-white font-[600] border border-solid
            border-smallTextColor py-2 px-4 rounded-[8px] max-h-[40px] hover:bg-smallTextColor hover:text-white
            hover:font-[500] ease-in duration-100"
              >
                <i class="ri-mail-line"></i>
                Reach Out !
              </button>
            </a>
          </div>

          <div className="w-full sm:w-1/2">
            <p className="text-gray-300 leading-7 mt-4 sm:mt-0 text-[20px]">
            "Innovate, Iterate, Code"
            </p>

            <div className="flex items-center gap-4 flex-wrap md:gap-8">
              <span className="text-gray-300 mt-8 font-[600] text-[20px]">
                Connect
              </span>
              <span className="w-[40px] h-[40px]  mt-8 bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="https://www.linkedin.com/in/dishit-vasani/"
                  className="text-gray-300 font-[500] text-[25px]"
                >
                  <i class="ri-linkedin-box-fill"></i>
                </a>
              </span>
              <span className="w-[40px] h-[40px]  mt-8 bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="https://github.com/dvasani15"
                  className="text-gray-300 font-[500] text-[25px]"
                >
                  <i class="ri-github-line"></i>
                </a>
              </span>
              <span className="w-[40px] h-[40px]  mt-8 bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="mailto:dvasani15@gmail.com"
                  className="text-gray-300 font-[500] text-[25px]"
                >
                  <i class="ri-mail-line"></i>
                </a>
              </span>
              <span className="w-[40px] h-[40px]  mt-8 bg-[#2b2d33] p-1 rounded-[50px] cursor-pointer text-center">
                <a
                  href="https://www.instagram.com/dishit_v/"
                  className="text-gray-300 font-[500] text-[25px]"
                >
                  <i class="ri-instagram-line"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* personal footer branding */}
      <div className="bg-[#00070D] py-3 mt-14">
        <div className="container">
          <div className="flex items-center justify-center sm:justify-between">
            <div className="hidden sm:block">
              <div className="flex items-center gap-[10px]">
                <span
                  className="w-[35px] h-[35px]  bg-white text-black text-[18px] font-[500]
                     rounded-full flex items-center justify-center"
                >
                  DV
                </span>

                <div className="leading-[20px]">
                  <h2 className="text-xl text-white font-[700]">
                    Dishit Vasani
                  </h2>
                </div>
              </div>
            </div>
            <div>
              <p className="text-gray-400 text-[14px]">
                Copyright &copy; {year} <br />
                <b>Dishit Vasani</b>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
