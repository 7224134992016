import React, { useRef, useState } from "react";
import emailsjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
    website: "", // Hidden honeypot field
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    let isValid = true;

    if (
      formData.user_name.trim() === "" &&
      formData.user_email.trim() === "" &&
      formData.message.trim() === ""
    ) {
      window.alert("Please Fill The Form Details !");
      isValid = false;
    } else if (formData.user_name.trim() === "") {
      window.alert("Name Is Required");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.user_email)) {
      window.alert("Valid Email Required !");
      isValid = false;
    } else if (formData.message.trim() === "") {
      window.alert("Message Is Required !");
      isValid = false;
    } else if (formData.website !== "") {
      // Check the honeypot field; if it has a value, reject the submission
      window.alert("Bot Detected!");
      isValid = false;
    }

    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "message" && value.length > 1000) {
      window.alert("Message Max Limit 1000 Characters !");
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const templateParams = {
        to_name: "Dishit Vasani",
        from_name: formData.user_name,
        from_email: formData.user_email,
        message: formData.message,
      };

      emailsjs
        .send(
          "service_i5eutdo",
          "template_032p9sc",
          templateParams,
          "hQRY5Q3oIx5Q3l_6S"
        )
        .then(
          (result) => {
            setIsSubmitted(true);
            window.alert("Thanks! Will Get In Touch Soon ;) ");
            // Reset the form fields
            setFormData({
              user_name: "",
              user_email: "",
              message: "",
              website: "", // Reset the honeypot field
            });
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <section id="contact" className="pb-16">
      <div className="container">
        <h2 className="text-headingColor font-[700] text-[2.5rem] mb-8">
          Get In Touch
        </h2>
        <div className="md:flex justify-between items-center">
          <div className="w-full md:w-1/2 h-300px sm:h-[450px]">
          <iframe
              title="google-maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184552.30943582457!2d-79.37805805!3d43.7182412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1698885042150!5m2!1sen!2sca"
              className="border-0 w-full h-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center bg-black px-4 lg:px-8 py-8">
            <form
              className="w-full"
              ref={form}
              onSubmit={sendEmail}
              style={{
                backgroundColor: "black",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div className="mb-5">
                <input
                  type="text"
                  name="user_name"
                  placeholder="Enter Your Name"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  value={formData.user_name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-5">
                <input
                  type="text"
                  name="user_email"
                  placeholder="Enter Your Email"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  value={formData.user_email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-5">
                <textarea
                  type="text"
                  name="message"
                  rows={3}
                  placeholder="Write Your Message"
                  className="w-full p-3 focus:outline-none rounded-[5px]"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              
              <div style={{ display: "none" }}>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>

              <button
                type="submit"
                className="w-full p-3 focus:outline-none rounded-[5px] bg-smallTextColor text-white hover:bg-headingColortext-center ease-linear duration-150"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
