import React, { useState, useEffect } from "react";
import data from "../../data/portfolioData";
import Modal from "./Modal";

const Portfolio = () => {
  const [nextItems, setNextItems] = useState(10);
  const [portfolios, setPortfolios] = useState(data);
  const [selectTab, setSelectTab] = useState("all"); // Set "all" by default
  const [showModal, setShowModal] = useState(false);

  const loadMoreHandler = () => {
    setNextItems((prev) => prev + 3);
  };

  useEffect(() => {
    const filterPortfolios = () => {
      if (selectTab === "all") {
        setPortfolios(data);
      } else {
        const filteredData = data.filter((item) => item.category === selectTab);
        setPortfolios(filteredData);
      }
    };

    filterPortfolios();
  }, [selectTab]);

  const renderPortfolioItem = (portfolio, index) => (
    <div
      key={index}
      data-aos="fade-zoom-in"
      data-aos-delay="50"
      data-aos-duration="1000"
      className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-[1]"
    >
      <figure>
        <img className="rounded-[8px]" src={portfolio.imgUrl} alt="" />
      </figure>

      <div className="w-full h-full bg-primaryColor bg-opacity-40 absolute top-0 left-0 z-[5] hidden group-hover:block">
        <div className="w-full h-full flex items-center justify-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={portfolio.siteUrl}
            className="text-white bg-headingColor hover:bg-smallTextColor py-2 px-4 rounded-[8px] font-[500] ease-in duration-200"
          >
            Details
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <section id="portfolio">
      <div className="container">
        <div className="flex items-center justify-between flex-wrap mb-7 sm:mb-0">
          <h3 className="text-headingColor text-[2.5rem] font-[700]">Projects</h3>

          <div className="flex gap-4">
            <button
              onClick={() => setSelectTab("all")}
              className="portfolio-button"
            >
              All
            </button>
          </div>
        </div>

        <div className="flex items-center gap-4 flex-wrap mt-12">
          {portfolios.slice(0, nextItems).map(renderPortfolioItem)}
        </div>

        <div className="text-center mt-6">
          {nextItems < portfolios.length && data.length > 6 && (
            <button onClick={loadMoreHandler} className="portfolio-button">
              Load More
            </button>
          )}
        </div>
      </div>
      {showModal && <Modal setShowModal={setShowModal} />}
    </section>
  );
};

export default Portfolio;
